<template>
    <template v-if="is_preview">
        <preview-notification entity="product"></preview-notification>
    </template>
    <article class="o-flex--grow u-bg-light-gray">
        <hero-slider :image_object="product.image_object" :hero_size="'medium'" :is_slider="false"></hero-slider>
        <section v-if="product" class="u-margin-vertical-large">
            <div class="o-row u-margin-vertical-large o-flex--center">
                <div class="o-col o-col--sm-12 o-col--lg-10">
                    <h1 v-if="product.title" class="c-heading--large u-no-margin-top u-margin-bottom-small">{{ product.title }}</h1>
                    <div v-if="product.price_description || product.old_price_description" class="u-margin-bottom-small">
                        <span v-if="product.price_description" class="u-font-weight-bold u-font-large">{{ product.price_description }}</span>
                        <div v-if="product.old_price_description" class="u-color-dark-gray">
                            <span>{{ product.old_price_description }}</span>
                        </div>
                    </div>
                    <button v-on:click.prevent="goToOrderForm" class="c-button c-button--solid">
                        {{ product.button_text ? product.button_text : translate('js.frontend.card_button_text') }} <span class="c-button__icon far fa-arrow-right"></span>
                    </button>
                    <div v-if="product.description" v-html="product.description" class="u-margin-top-medium"></div>
                    <div v-if="product.image_slider && product.image_slider.length > 0" class="u-margin-top-medium">
                        <image-wrapper :images="product.image_slider" :title="product.title ? product.title : null"></image-wrapper>
                    </div>
                </div>
            </div>
            <div v-if="product.location || (product.is_widget_active && widgetHtml)" class="o-row o-flex--center u-margin-vertical-medium">
                <div v-if="product.location" v-html="product.location" :class="[(product.is_widget_active && widgetHtml) ? 'o-col--md-6 o-col--lg-5' : 'o-col--lg-10']" class="o-col o-col--sm-12 u-margin-bottom-small"></div>
                <div v-if="product.is_widget_active && widgetHtml" v-html="widgetHtml" class="o-col o-col--sm-12 o-col--md-6 o-col--lg-5 c-widget"></div>
            </div>
            <div v-if="product.conditions" class="o-row o-flex--center u-margin-vertical-medium">
                <div v-html="product.conditions" class="o-col o-col--md-12 o-col--lg-10"></div>
            </div>
        </section>
        <section-ticket-select :product="product" ref="ticket_form"></section-ticket-select>
    </article>
</template>

<script>
    import HeroSlider           from '../Components/HeroSlider';
    import Image                from '../Components/Image';
    import SectionTicketSelect  from '../Sections/section-ticket-select';
    import PreviewNotification  from '../Components/UI/PreviewNotification.vue';

    export default {
        props: {
            product: Object,
            is_preview: Boolean
        },
        components: {
            'hero-slider'           : HeroSlider,
            'image-wrapper'         : Image,
            'section-ticket-select' : SectionTicketSelect,
            'preview-notification'  : PreviewNotification
        },
        data: () => {
            return {
                product_variants: [],
                widget_html: null,
            }
        },
        computed: {
            /**
             * Sets correct widget html code
             *
             * @author Bas Lokerman <bas@click.nl>
             * @return {mixed} null|widget_url
             */
            widgetHtml() {
                //Check if the embed link is set and the widget is set to active
                if(this.product.widget_embed_link && this.product.is_widget_active == true){
                    let widget_string = this.product.widget_embed_link;
                    let dom_widget_html = new DOMParser().parseFromString(widget_string, "text/xml");

                    if(dom_widget_html.firstChild.tagName == 'a'){

                        //Set default widget attributes
                        dom_widget_html.firstChild.innerHTML = Lang.get('js.frontend.checkout.9292_widget_title');
                        dom_widget_html.firstChild.setAttribute('data-9292-height', '575');
                        dom_widget_html.firstChild.setAttribute('data-9292-width', '100%');

                        //Serialize to HTML
                        let xml_serializer = new XMLSerializer();
                        let widget_html = xml_serializer.serializeToString(dom_widget_html.firstChild);

                        return widget_html;
                    }
                }
                return null;
            },
        },
        mounted() {
            //Set 9292 widget
            if(this.product.widget_embed_link && this.product.is_widget_active == true){
                let widget9292 = document.createElement('script');
                widget9292.setAttribute('src', 'https://9292.nl/static/js/widget.js');
                document.head.appendChild(widget9292);
            }
        },
        methods: {
            goToOrderForm: function() {
                this.$refs.ticket_form.$el.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
</script>
