<template>
    <div class="c-hero">
        <section class="c-hero--featured u-full-width u-max-w-xl u-bg-gradient u-xl-rounded-md">
            <img :src="`${$page.props.cdn_url}/images/frontend/hero-top-swirl.svg`" class="c-hero__swirl--small">
            <img :src="`${$page.props.cdn_url}/images/frontend/hero-bottom-swirl.svg`" class="c-hero__swirl--large">
            <div class="o-row o-flex--middle">
                <div class="o-col o-col--sm-12 o-col--lg-5 u-color-white u-lg-no-padding-right">
                    <div class="c-hero__content">
                        <h1 class="c-hero__title u-no-margin-top">{{ data.title }}</h1>
                        <p>{{ data.subtitle }}</p>
                        <div v-if="data.translated_first_button && data.translated_first_button.button_text && data.translated_first_button.button_url" :class="'o-flex o-flex--' + this.text_alignment">
                            <template v-if="data.translated_first_button.is_button_target_blank === '1'">
                                <a :href="data.translated_first_button.button_url" target="_blank" rel="noopener" class="c-button c-button--hollow c-button--white u-margin-top-xsmall">
                                    {{ data.translated_first_button.button_text }}
                                </a>
                            </template>
                            <template v-else>
                                <inertia-link :href="data.translated_first_button.button_url" :class="'c-button c-button--hollow c-button--white u-margin-top-xsmall'">
                                    {{ data.translated_first_button.button_text }}
                                </inertia-link>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="o-col o-col--sm-12 o-col--lg-7 u-lg-no-padding-left">
                    <responsive-image
                        :is_background_image="false"
                        :class_name="'c-hero__image u-full-width u-rounded-md'"
                        :small="data.image_object.image_small"
                        :medium="data.image_object.image_medium"
                        :large="data.image_object.image_large"
                        :alt_text="'alt'">
                    </responsive-image>
                </div>
            </div>
        </section>
        <div class="c-hero__cards o-row">
            <card-slider :cards="cards"></card-slider>
        </div>
    </div>
</template>

<script>
    import ResponsiveImage from './ResponsiveImage';
    import CardSlider from './CardSlider';

    export default {
        props: {
            data : Object,
            cards : Object,
            image_object: Object,
            is_first_heading_tag : Boolean
        },
        components: {
            'responsive-image' : ResponsiveImage,
            'card-slider' : CardSlider
        },
        data() {
            return {
            }
        },
        mounted() {
            // Check if the body already has the class
            if (!document.body.classList.contains('u-padding-top-xxlarge')) {
                // Add class to body before the component is rendered
                document.body.classList.add('u-padding-top-xxlarge');
            }
        },
        unmounted() {
            // Check if the class exists before removing
            if (document.body.classList.contains('u-padding-top-xxlarge')) {
                // Remove class from body when the component is destroyed
                document.body.classList.remove('u-padding-top-xxlarge');
            }
        },
        methods: {

        }
    }
</script>
